import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  navStart: Observable<NavigationStart>;
  navEnd: Observable<NavigationEnd>;

  constructor(private router: Router) {
    this.navStart = router.events.pipe(
      filter(evt => evt instanceof NavigationStart)
    ) as Observable<NavigationStart>;
    this.navEnd = router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  environment = environment;

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }

  ngOnInit() {
    this.navStart.subscribe(evt => {
      // console.log('Hide Scroll!');
      this.scrollFunction();
    });
    this.navEnd.subscribe(evt => {
      // console.log('Redo Scroll!');
      window.scrollTo(0, 0);
      this.scrollFunction();
    });
  }

  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    // console.log(`window.pageYOffset = ${window.pageYOffset} body.scrollTop = ${document.body.scrollTop} documentElement.scrollTop = ${document.body.scrollTop}`);
    if (window.pageYOffset > 20 || document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        // console.log("Should display back-to-top button");
        document.getElementById("back-to-top").style.display = "block";
    } else {
      // console.log("Should hide back-to-top button");
      document.getElementById("back-to-top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  } 
}
