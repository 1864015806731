import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from '../shared';
// import { AboutComponent } from './about/about.component';
// import { BlogComponent } from './blog/blog.component';
// import { CommunityComponent } from './community/community.component';
// import { ServicesComponent } from './services/services.component';



const routes: Routes = [
  // { 
  //   path: 'about',
  //   loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  // },
  // { 
  //   path: 'blog',
  //   loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
  // },
  // { 
  //   path: 'community',
  //   loadChildren: () => import('./community/community.module').then(m => m.CommunityModule)
  // },
  // { path: 'services', component: ServicesComponent },
  // { 
  //   path: 'services',
  //   loadChildren: () => import('./services/services.module').then(m => m.ServicesModule)
  // },
  // { 
  //   path: 'products',
  //   loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  // },
  { path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot( routes, {
    enableTracing: false, // <-- debugging purposes only
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 80] // [x, y]
  })
  ],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
