import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
// import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  NavigationComponent,
  FooterComponent,
  PageNotFoundComponent,
  ConstructionComponent,
} from './';

import { UIErrorHandler } from './ui-error-handler';

@NgModule({
  declarations: [
    NavigationComponent,
    FooterComponent,
    PageNotFoundComponent,
    ConstructionComponent,
  ],
  entryComponents: [
  ],
  imports: [
    CommonModule,
    NgbModule,
    // HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: UIErrorHandler
    }
  ],
  exports: [
    NavigationComponent,
    FooterComponent,
    PageNotFoundComponent,
    ConstructionComponent,
  ]
})
export class SharedModule { }
