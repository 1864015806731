import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { HomeComponent } from './home/home.component';

import { HomeRoutingModule } from './home-routing.module';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { HomeNewsComponent } from './home-news/home-news.component';
import { HomeItemsComponent } from './home-items/home-items.component';

@NgModule({
  declarations: [
    HomeBannerComponent,
    HomeNewsComponent,
    HomeItemsComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule
  ]
})
export class HomeModule { }
