import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './shared';

const routes: Routes = [
  { 
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },
  { 
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  { 
    path: 'community',
    loadChildren: () => import('./community/community.module').then(m => m.CommunityModule)
  },
  { 
    path: 'customer',
    loadChildren: () => import('./customer-portal/customer-portal.module').then(m => m.CustomerPortalModule)
  },
  { 
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  { 
    path: 'services',
    loadChildren: () => import('./services/services.module').then(m => m.ServicesModule)
  },
  { 
    path: 'training',
    loadChildren: () => import('./training-portal/training-portal.module').then(m => m.TrainingPortalModule)
  },
  { 
    path: 'login',
    loadChildren: () => import('./shared/modules/login/login.module').then(m => m.LoginModule)
  },
  { 
    path: 'register',
    loadChildren: () => import('./shared/modules/register/register.module').then(m => m.RegisterModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot( routes, {
    enableTracing: false, // <-- debugging purposes only
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 40] // [x, y]
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
