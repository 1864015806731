import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  private currentDropdown: any;
  private closingDropdown: any;

  constructor(private router: Router) {
    this.currentDropdown = null;
    this.closingDropdown = null;
  }

  public isMenuCollapsed = true;

  public isLinkActive(url) {
    let charPos = this.router.url.indexOf('?');
    let cleanUrl = charPos !== -1 ? this.router.url.slice(0, charPos) : this.router.url;
    return (cleanUrl === url);
  }

  mobileScreenWidth() {
    if (window.screen.width < 900 ) {
      return true;
    }
  }

  navClicked() {
    this.isMenuCollapsed = true;
  }

  openDropdown(item: any) {
    // console.log(`openDropdown() opening dropdown ${item._anchor.anchorEl.innerText}`);
    if ( this.closingDropdown == null ) {
      // console.log("openDropdown() closingDropdown == null");
      if ( this.currentDropdown == null ) {
        // console.log("openDropdown() currentDropdown == null");
        item.open();
        this.currentDropdown = item;
        // console.log(`openDropdown() currentDropdown == ${this.currentDropdown._anchor.anchorEl.innerText}`);
      } else if (this.currentDropdown !== item ) {
        // console.log("openDropdown() currentDropdown !== item");
        this.currentDropdown.close();
        item.open();
        this.currentDropdown = item;
      } else {
        // console.log("openDropdown() do nothing, currentDropdown == item");
      }
    } else {
      if ( this.closingDropdown === item ) {
        // console.log(`openDropdown() closingDropdown === item ${item._anchor.anchorEl.innerText}, cancelling close`);
        this.closingDropdown = null;
      } else {
        // console.log(`openDropdown() closingDropdown ${this.closingDropdown._anchor.anchorEl.innerText} !== item ${item._anchor.anchorEl.innerText}, opening dropdown ${item._anchor.anchorEl.innerText}`);

        item.open();
        this.currentDropdown = item;
        this.closingDropdown.close();
        this.closingDropdown = null;
      }
    }
  }

  closeDropdown(item: any) {
    let vm = this;
    // console.log(`closeDropdown() start closing dropdown ${item._anchor.anchorEl.innerText}`);

    vm.closingDropdown = item;
    // console.log(`closeDropdown() closingDropdown === ${vm.closingDropdown._anchor.anchorEl.innerText}`);

    vm.currentDropdown = null;
    // console.log(`closeDropdown() Setting currentDropdown to null`);
    
    setTimeout(function(){
      if (vm.closingDropdown == null ) {
        // console.log(`closeDropdown() closingDropdown === null`);
      } else {
        // console.log(`closeDropdown() reached timeout, closing dropdown ${vm.closingDropdown._anchor.anchorEl.innerText}`);
        vm.closingDropdown.close();

        // console.log(`closeDropdown() closed dropdown ${vm.closingDropdown._anchor.anchorEl.innerText}, Setting closingDropdown to null`);
        vm.closingDropdown = null;
      }
    }, 800);
  }
}
